<script>
import Layout from "../../layouts/main";
import EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';
import axios from 'axios';

import base_domain from '../../myvars'
axios.defaults.baseURL = base_domain

export default {

  components: {
    Layout,
    EasyDataTable
  },
  data() {
    return {
        headers:[
            { text: "Staff Name", value: "staffname" },
            { text: "Date", value: "logdate" },
            { text: "Activity", value: "appactivity"},
        ],
        items: [],
        arrItem:[],
    }
  },
  created()
  {
    // this.arrItem = this.items
    this.get_logsssss();
  },
  methods: {
    filterStatus(){
      if(this.selectFilterSort==null)
      {
        this.arrItem = this.items
      }
      else{
        this.arrItem = this.items.filter((item)=>{
          return  item.level==this.selectFilterSort
      })
      }
    },
    get_logsssss(){
    let arrList =[]
    var token = localStorage.getItem("accessToken");
        axios.post('/accounts/get_logs/',{token: token,})
            .then(response => {
            let responseList = response.data.log_data;
                responseList.forEach(item => {
                console.log('this i',item.status)
                let newItem = {
                    staffname: "You",
                    dateLog: item.created_on,
                    activity: item.log,
                };
                arrList.push(newItem);
                });
                this.arrItem = arrList;

            })
            .catch(error => {
            console.error(error);
            });
  },
  }
  

};
</script>

<template>
  <Layout>
    <main class="py-4">
        <div class="row mb-3">
            <div class="col">
                <br><br>
                <h2 class="font-size-22 text-theme-dark">View Log</h2>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <EasyDataTable
                :headers="headers"
                :items="arrItem"
                :search-value="searchValueTable"
                border-cell
                theme-color="#df6a0d"
                :rows-per-page="10"
                buttons-pagination
                table-class-name="table-custom-style"
                    >
                <template #item-logdate="{dateLog , timeLog }">
                <p class="mb-0"><strong>{{ dateLog}}</strong></p>
                <p class="mb-0">{{timeLog}}</p>
                </template>
                <template #item-appactivity="{activity , section }">
                  <p class="mb-0">{{ activity }} <strong>{{ section }}</strong></p>
                </template>
                </EasyDataTable>
            </div>
        </div>
    </main>
  </Layout>
</template>